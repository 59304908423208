@keyframes floatingButtonAppear {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes floatingButtonDisappear {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

@keyframes modal-enter {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes modal-exit {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}

.floatingbutton-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
}

.floatingbutton-modal-content {
    background: linear-gradient(to bottom right, #f48e28, #6C441A);
    margin: 0;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    animation: modal-enter 0.3s ease-out forwards;
    color: white;
    width: 80%;
    max-width: 500px;
}

.floatingbutton-close {
    color: white;
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 30px;
    cursor: pointer;
    transition: transform 0.3s;
}

.floatingbutton-close:hover {
    transform: rotate(90deg);
}

.floatingbutton-modal-exit {
    animation: modal-exit 0.3s ease-in forwards;
}

.floatingbutton-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background: linear-gradient(to right, #f48e28, #6C441A);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0s, transform 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.floatingbutton-button:hover {
    transform: scale(1.05);
}

.floatingbutton-button.floatingbutton-visible {
    animation: floatingButtonAppear 0.3s ease-out forwards;
}

.floatingbutton-button.floatingbutton-hidden {
    animation: floatingButtonDisappear 0.3s ease-in forwards;
}

.floatingbutton-header {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 5px;
}

.floatingbutton-subtext {
    font-size: 14px;
    font-weight: 400;
}

.floatingbutton-form {
    display: flex;
    flex-direction: column;
    padding-top: 30px;    
}

/* Styling for the country code dropdown */
.floatingbutton-form select {
    margin-bottom: 15px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.floatingbutton-form select:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
}

.floatingbutton-form input,
.floatingbutton-form button {
    margin-bottom: 15px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.floatingbutton-form input:focus,
.floatingbutton-form button:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
}

.floatingbutton-form button {
    background: #fff;
    color: #f48e28;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.floatingbutton-form button:hover {
    transform: translateY(-2px);
}